import * as React from "react"
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import PageDetails from "../../../components/PageDetails";
import cadImg from "../../../images/canada-image-1.png"

let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "" : process.env.FR_URL) + "/fr/a-propos/";
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/about/";

const About = () => {
    return (<>
        <Header title={"À propos | Connecteur d'emplois gouvernementale des affaires mondiales Canada"}
                description={"À propos | Connecteur d'emplois gouvernementale des affaires mondiales Canada"}
                lang={"FR"}
                langLink={en_url}
                breadcrumbs={[{text: "Bénéfices de travailler pour le Gouvernement du Canada à l’étranger", url: fr_url}]}
                bodyClasses={"page-fr-about"}/>
        <main property="mainContentOfPage" resource="#wb-main" class="container pb-5" typeof="WebPageElement">
            <h1>Bénéfices de travailler pour le Gouvernement du Canada à l’étranger</h1>
            <h2 id={"mandat"}>Notre mandat</h2>
            <p>Affaires mondiales Canada collabore avec d’autres ministères et d’autres paliers de gouvernement dans le cadre d’une approche pancanadienne.  Consultez <a href={"https://www.international.gc.ca"} rel="noreferrer"

                target={"_blank"}>notre mandat.</a></p>
            <img src={cadImg} alt={"Canada"}/>
            <h2 id={"engagement"}>Notre engagement</h2>
            <p>Pour le bien-être de la main-d’œuvre locale à l’étranger, le gouvernement du Canada et ses partenaires
                fournissent:</p>
            <ul>
                <li>de bonnes conditions de travail</li>
                <li>un environnement qui favorise la diversité et l’inclusion</li>
                <li>des activités de transfert d’aptitudes et de compétences et de l’apprentissage continu</li>
            </ul>
            <h2 id={"valeurs"}>Nos valeurs fondamentales</h2>
            <p>Les fonctionnaires<sup>*</sup> sont tenus de se comporter conformément aux
                valeurs et aux comportements attendus suivants:</p>
            <ul>
                <li>Respect de la démocratie</li>
                <li>Respect envers les personnes</li>
                <li>Intégrité</li>
                <li>Intendance</li>
                <li>Excellence</li>
            </ul>
            <p>Les employés doivent faire preuve de ces valeurs au Canada et à l’étranger:</p>
            <ul>
                <li>dans le cadre de leur travail quotidien</li>
                <li>au sein de leur service</li>
                <li>dans le cadre de leur relation avec:</li>
                <ul>
                    <li>les intervenants (autres ministères, autres gouvernements, clients du secteur privé, etc.)
                    </li>
                    <li>les entrepreneurs</li>
                    <li>le public</li>
                </ul>
            </ul>
            <p>Ces valeurs et comportements respectent le Code de valeurs et d’éthique du secteur public. Vous ne
                pouvez
                pas les considérer distinctement, car ils se chevauchent souvent.  <b>*Ceci comprend les employés canadiens et les employés recrutés sur place.</b></p>
            <h2 id={"diversite"}>Diversité et inclusion</h2>
            <p>Les gens et la diversité comptent. Notre force réside dans la diversité de notre personnel et dans les
                idées qu’il génère. Nos valeurs fondamentales incluent de traiter toute personne avec respect, dignité et
                équité, l’engagement et l’inclusion étant les éléments constitutifs d’un milieu de travail sain.
            </p>
            <p>Notre organisation offre un milieu de travail inclusif. Le respect, le travail d’équipe et la
                collaboration font partie de notre culture. Les missions du Canada à l’étranger s’engagent à appuyer un
                milieu exempt de harcèlement et de discrimination. Elles encouragent les employés à apprendre et à
                renforcer leurs aptitudes et leurs compétences en encourageant l’apprentissage continu tout au long de
                leur carrière.</p>
            <PageDetails lang={"FR"} dateModified={"2022-01-05"}/>
        </main>
        <Footer lang={"FR"}/>
    </>)
}
export default About;